@font-face {
  font-family: 'Walsheim-Medium';
  src: url('../fonts/GT-Walsheim/GT-Walsheim-Medium.eot');
  src: local('GT Walsheim Pro Medium'), local('GTWalsheimProMedium'),
    local('GT Walsheim Medium'), local('GTWalsheimMedium'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Medium.woff2') format('woff2'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Medium.otf') format('OpenType'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Medium.woff') format('woff'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Walsheim-Light';
  src: url('../fonts/GT-Walsheim/GT-Walsheim-Light.eot');
  src: local('GT Walsheim Pro Light'), local('GTWalsheimProLight'),
    local('GT Walsheim Light'), local('GTWalsheimLight'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Light.woff2') format('woff2'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Light.otf') format('OpenType'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Light.woff') format('woff'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Walsheim-Regular';
  src: url('../fonts/GT-Walsheim/GT-Walsheim-Regular.eot');
  src: local('GT Walsheim Pro Regular'), local('GTWalsheimProRegular'),
    local('GT Walsheim Regular'), local('GTWalsheimRegular'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Regular.woff2') format('woff2'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Regular.otf') format('OpenType'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Regular.woff') format('woff'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Walsheim-Bold';
  src: url('../fonts/GT-Walsheim/GT-Walsheim-Bold.eot');
  src: local('GT Walsheim Pro Bold'), local('GTWalsheimProBold'),
    local('GT Walsheim Bold'), local('GTWalsheimBold'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Bold.woff2') format('woff2'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Bold.otf') format('OpenType'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Bold.woff') format('woff'),
    url('../fonts/GT-Walsheim/GT-Walsheim-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/Inter/inter-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/Inter/inter-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/Inter/inter-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/Inter/inter-bold.woff2') format('woff2');
}
